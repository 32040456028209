import { useEffect } from "react";
import Service from "../../components/service";
import { travelData } from "../../constants/services";
import classes from "../business/business.module.css";

const Travel = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.main}>
      <div className={classes.bizzContent}>
        <span>OUR SERVICES</span>
        <h1>Travels &amp; Tours</h1>
        <p>
          One week in Maldives or Two Weeks in Santorini. We are the one-stop
          shop for every travel service available in the industry.
          <br /> We provide the ultimate travel planning experience for people
          who want to explore. Ranging from Visa Processing, Tour Packages, and
          Hotel reservations.
        </p>
      </div>
      <div className={classes.bizzCard}>
        {/* {travelData.map((item, index) => (
          <Service
            index={index}
            {...item}
            key={index}
            modalImage={travelData[index].image}
            modalText={travelData[index].text}
            modalTitle={travelData[index].title}
          />
        ))} */}
      </div>
    </div>
  );
};

export default Travel;
