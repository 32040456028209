// @ts-nocheck
import axios from "axios";
import { useState } from "react";
import classes from "./book.module.css";
import Input from "./inputTwo";
import { toast } from "react-toastify";

const Book = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    company: "",
    enquiry: "",
    message: "",
  });
  const handleChange = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(payload);
    setHasSubmitted(true);

    try {
      setLoading(true);
      const response = await axios({
        url: "https://api.intrapair.com/api/v1/contact-us/emconsult",
        method: "POST",
        data: payload,
        headers: {
          "content-type": "application/json",
        },
      });
      // console.log(response);
      toast.success(response.data.message);
      setLoading(false);
      setHasSubmitted(false);
      setPayload({
        fullName: "",
        email: "",
        phoneNumber: "",
        company: "",
        enquiry: "",
        message: "",
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className={classes.main}>
      <h2>
        Do you have any question? Book a FREE consultation or speak to our
        advisors.
      </h2>
      <form>
        <div className={classes.inputs}>
          {inputArray.map((input, i) => (
            <Input
              key={`inputfield-${i}`}
              value={payload[input.name]}
              isValid={input.validation(payload[input.name])}
              handleChange={handleChange}
              hasSubmitted={hasSubmitted}
              {...input}
            />
          ))}
        </div>
        <label htmlFor="select">Select your enquiry</label>
        <select
          name="enquiry"
          id="select"
          onChange={handleChange}
          value={payload.enquiry}
        >
          <option value="" disabled>
            ---
          </option>
          <option value="business">Business & Management Consulting</option>
          <option value="edu">Edu-Migration</option>
          <option value="training">Training & Human Capital Development</option>
          <option value="marketing">Marketing, Communication & PR</option>
          <option value="travel">Travels & Tours</option>
          <option value="events">Events and Entertainment</option>
          <option value="others">Other Subjects</option>
        </select>
        {payload.enquiry === "" && hasSubmitted && (
          <span className={classes.message}>Please select an option</span>
        )}
        <label className={classes.textarea}>Your Message*</label>
        <textarea
          name="message"
          onChange={handleChange}
          value={payload.message}
          id=""
          placeholder="Oya! Talk to us"
        ></textarea>
        {payload.message < 3 && hasSubmitted && (
          <span className={classes.message}>Please select an option</span>
        )}
        <div className={classes.btn}>
          <button onClick={handleSubmit}>
            {loading ? "Loading" : "Send Message"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Book;

export const inputArray = [
  {
    label: "Full name",
    type: "text",
    name: "full_name",
    placeholder: "Ciroma Chukwuma Adekunle",
    _id: "fullName",
    errorMessage: "First name must be at least 3 characters",
    validation: (value) => value ? value.length > 1 : false,
  },
  {
    label: "Email Address*",
    type: "email",
    name: "email",
    placeholder: "example@mail.com",
    _id: "email",
    errorMessage: "Please enter a valid email",
    validation: (value) => emailCheck(value),
  },
  {
    label: "Phone Number",
    type: "tel",
    name: "phone_number",
    placeholder: "+234",
    _id: "phoneNumber",
    errorMessage: "Invalid Phone (+234)",
    validation: (value) => phoneCheck(value),
  },
  {
    label: "Company (Optional)",
    type: "text",
    name: "company",
    placeholder: "",
    _id: "company",
    errorMessage: "Invalid Address",
    validation: (value) => emailCheck(value),
  },
];
export const emailCheck = (value) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value);
};
export const phoneCheck = (value) => {
  const regex = /^(?=.*[0-9])(?=.*[+])[0-9+]{14}$/;
  return regex.test(value);
};
