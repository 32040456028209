import service1 from "../assets/service-1.svg";
import business1 from "../assets/business-1.svg";
import Bizz from "../assets/bizz.png";
import Bizz1 from "../assets/bizz-1.png";
import Bizz2 from "../assets/bizz-2.png";
import Bizz3 from "../assets/bizz-3.png";
import Bizz4 from "../assets/bizz-4.png";
import Bizz5 from "../assets/bizz-5.png";
import Bizz6 from "../assets/bizz-6.png";
import Edu from "../assets/edu.png";
import Edu1 from "../assets/edu-1.png";
import Edu2 from "../assets/edu-2.png";
import Edu3 from "../assets/edu-3.png";
import Edu4 from "../assets/edu-4.png";
import Edu5 from "../assets/edu-5.png";
import Edu6 from "../assets/edu-6.png";
import Edu7 from "../assets/edu-7.png";
import Edu8 from "../assets/edu-8.png";
import Edu9 from "../assets/edu-9.png";
import Train from "../assets/train.png";
import Train1 from "../assets/train-1.png";
import Train2 from "../assets/train-2.png";
import Train3 from "../assets/train-3.png";
import Train4 from "../assets/train-4.png";
import Tour from "../assets/tour.png";
import Event from "../assets/event.png";
import Event1 from "../assets/event-1.png";
import Event2 from "../assets/event-2.png";
import Event3 from "../assets/event-3.png";
import Pr from "../assets/pr.png";
import Pr1 from "../assets/pr-1.png";
import Pr2 from "../assets/pr-2.png";
import Pr3 from "../assets/pr-3.png";
import Pr4 from "../assets/pr-4.png";
import Pr5 from "../assets/pr-5.png";
import Pr6 from "../assets/pr-6.png";
import Pr7 from "../assets/pr-7.png";
import Pr8 from "../assets/pr-8.png";

export const servicesData = [
  {
    image: Bizz,
    url: "/service/business",
    title: "Business & Management Consulting",
    text1:
      "We offer a wide variety of business and management consulting services that bridge the gap between the current and future state of your brand.",
  },
  {
    image: Edu,
    url: "/service/edu-migration",
    title: "Edu-Migration",
    text1:
      "Our EduMigration consultancy is aimed at providing a seamless process for educational ambition globally to fulfill career goals and a better lifestyle.",
  },
  {
    image: Train,
    url: "/service/training",
    title: "Training & Human Capital Development",
    text1:
      "Our Human Capital Development programs are centered around upskilling and reskilling people for digital transformation and skill expansion.",
  },
  {
    image: Tour,
    url: "/service/travel-tour",
    title: "Travels & Tours",
    text1:
      "One week in Maldives or Two Weeks in Santorini. We are the one-stop shop for every travel service available in the industry.",
  },
  {
    image: Event,
    url: "/service/event",
    title: "Events and Entertainment",
    text1:
      "We offer event management services aimed at planning and executing your events to the standard state.",
  },
  {
    image: Pr,
    url: "/service/marketing",
    title: "Marketing, Communication & PR",
    text1: "Marketing isn't everyone's forte, but it is ours.",
  },
];
export const businessData = [
  {
    image: Bizz1,
    isButton: true,
    title: "Branding (Identity, logo design, flyers)",
    text: "First impressions last longer and tackling marketing problems through the use of visual communication delights us. Our experienced design team visualizes and interprets our customers’ objectives into realistic designs while ensuring they represent the brand’s identity and portray your brand value.",
  },
  {
    image: Bizz2,
    isButton: true,
    title: "Brand strategy",
    text: "Every brand's digital engagement requires an ingenious strategy. We offer ground-breaking ideas customized to meet our client's needs and set them on a pedestal above their competitors.",
  },
  {
    image: Bizz3,
    isButton: true,
    title: "Business plan development",
    text: "Thriving in an already saturated market world needs a solid business plan to stand out. We have the expertise to create and build a business plan to shape your brand in a way that aligns with your company’s vision and mission, as well as resonates with your target audience.",
  },
  {
    image: Bizz4,
    isButton: true,
    title: "People management",
    text: "EM Consults provides ease of doing business by helping you handle all people-related tasks while you focus on other things.",
  },
  {
    image: Bizz5,
    isButton: true,
    title: "Web development",
    text: "A brand Website is a digital storefront and it contributes to the success of any business in the digital world. Our team helps you design websites to achieve maximum impact by prioritizing defined business goals, users and target audiences.",
  },
  {
    image: Bizz6,
    isButton: true,
    title: "App development",
    text: "Starting a brand is hard enough without having to worry about developing an effective online presence like a high-impact mobile app. Our Team takes the hassle out of the app development, so you can focus on what’s important- your business! Get in touch with us today to discuss your idea and how we can bring it to life.",
  },
];
export const eduData = [
  {
    image: Edu1,
    isButton: true,
    title: "Career Guidance & Advisory",
    text: "We work with and guide our clients to identify suitable career choices to increase their chances of admission, making informed decisions, and getting into the best program.",
  },

  {
    image: Edu2,
    isButton: true,
    title: "School Admission Processing",
    text: "Affiliated with international education entities, we ensure our clients get quick admission offersas long as needed documents have been submitted for review & assessment hence making their educational goals a reality.",
  },
  {
    image: Edu3,
    isButton: true,
    title: "Student Visa Application",
    text: "We provide assistance and processing services for students wishing to obtain a comprehensive visa and ensure they travel on time.",
  },
  {
    image: Edu4,
    isButton: true,
    title: "School Fees Payments",
    text: "We understand the struggle of cross-border payments and therefore provide access to fast and reliable school fees payment service that allows you to pay your student fees in the currency of your choice via bank transfer or crypto",
  },
  {
    image: Edu5,
    isButton: true,
    title: "Flight Bookings",
    text: "We help our clients plan and book their flights to ease the process of traveling so you don't have to worry about anything.",
  },
  {
    image: Edu6,
    isButton: true,
    title: "Accommodation Services",
    text: "Understanding the struggle of securing comfortable accommodation for international students, we assist our clients in ensuring a safe, convenient, and budget-friendly place to stay.",
  },
  {
    image: Edu7,
    isButton: true,
    title: "Excellent Support Service",
    text: "We make sure we take the hassle out of our client search for quality education, ranging from inquiries about visa documentation review, visa checklist, sops, and visa application support & guidance.",
  },
  {
    image: Edu8,
    isButton: true,
    title: "Pre-departure orientation & Touchdown Reception",
    text: "Adapting to a new environment can be daunting, understanding that, we provide our clients with important information before they travel and help them settle down in their new location by providing reception and welfare such as getting African foods, setting up a bank account & internet connection, and more.",
  },
  {
    image: Edu9,
    isButton: true,
    title: "Job Search & Recommendations",
    text: "Our education specialists provide responsive, expert advice & guidance on how you can get jobs and the kind of jobs you can do abroad.",
  },
];
export const trainingData = [
  {
    image: Train1,
    isButton: true,
    title: "Skill Up",
    text: "Due to increased Digitalization and innovation demands. There has been a conscious and unconscious transition of the economy from being analogue and knowledge-based to being digital and skills-based. The Skill Up Academy is a brainchild of Everything Middleman Consults focused on empowering people with relevant skills, strengthening competencies, niche carving, positioning and business development.",
  },

  {
    image: Train2,
    isButton: true,
    title: "CV/Resume review & writing",
    text: "Job searching is a full-time job on its own, and our team has mastered the best practices needed to get your dream job without having to go through the hassle and tussle of searching for it.",
  },
  {
    image: Train3,
    isButton: true,
    title: "LinkedIn Optimization",
    text: "Maximizing LinkedIn for professional development cannot be overemphasized at this age, and that's where we come in - to provide you with tailored pieces of training, resources and revamp to help you achieve your professional objective through LinkedIn.",
  },
  {
    image: Train4,
    isButton: true,
    title: "Personal statement",
    text: "High-quality and professional personal statements make you stand out. We have the best experienced and skilled Writers to write quality documents that are thoroughly screened for plagiarism and also match your CV.",
  },
];
export const travelData = [
  {
    image: business1,
    isButton: true,
    title: "Skill Up",
    text: "Due to increased Digitalization and innovation demands. There has been a conscious and unconscious transition of the economy from being analogue and knowledge-based to being digital and skills-based. The Skill Up Academy is a brainchild of Everything Middleman Consults focused on empowering people with relevant skills, strengthening competencies, niche carving, positioning and business development.",
  },

  {
    image: business1,
    isButton: true,
    title: "CV/Resume review & writing",
    text: "Job searching is a full-time job on its own, and our team has mastered the best practices needed to get your dream job without having to go through the hassle and tussle of searching for it.",
  },
  {
    image: business1,
    isButton: true,
    title: "LinkedIn Optimization",
    text: "Maximizing LinkedIn for professional development cannot be overemphasized at this age, and that's where we come in - to provide you with tailored pieces of training, resources and revamp to help you achieve your professional objective through LinkedIn.",
  },
  {
    image: business1,
    isButton: true,
    title: "Personal statement",
    text: "High-quality and professional personal statements make you stand out. We have the best experienced and skilled Writers to write quality documents that are thoroughly screened for plagiarism and also match your CV.",
  },
];
export const eventData = [
  {
    image: Event1,
    isButton: true,
    title: "Planning to Execution",
    text: "We take full responsibility for the creative arrangement, promotion, and technical and logistical elements that make your event memorable. Reach out to us to effect the changes on your next events.",
  },

  {
    image: Event2,
    isButton: true,
    title: "Event Branding & Marketing",
    text: "This aspect focuses on using project management techniques to establish and grow large-scale activities. It includes making research on an event idea, defining the target audience, devising the idea of the event, and planning the technical aspects before the event begins.     We engage in a wide range of practices such as content creation, social media management and influencer engagement to extend the reach of your event.",
  },
  {
    image: Event3,
    isButton: true,
    title: "Event Consultancy & Strategy.",
    text: "Over 10 years of experience in event planning and managing events. Our Skilled Event Managers offer comprehensive event consulting services tailored to the client's budgets, target audience, aims and objectives. You can always overcome the fear of not running a successful event by consulting us to refresh your old approach.",
  },
];
export const marketData = [
  {
    image: Pr1,
    isButton: true,
    title: "Social Media",
    text: "Every digital business – big or small – needs social media to survive and getting your social media game right might be the difference between hitting your business goals or not. Our team provides the expertise needed for you to hit your business objective through social media management and marketing.",
  },

  {
    image: Pr2,
    isButton: true,
    title: "Email Marketing",
    text: "Email marketing campaigns have proven to be an unconventional method of digital marketing to deliver a higher return on investment. Our expertise Email marketers have mastered the art of deploying updated email marketing campaigns for clients&#39; marketing strategies.",
  },
  {
    image: Pr3,
    isButton: true,
    title: "Content Creation",
    text: "Content is the lifeblood of social media. Leading digital brand exists on a consistent creation of creative content that speaks directly to your prospects and builds a conversation around your brand. Here at EM Consults, we produce the best digital contents that sell and lead in the market. Our multi-skilled and intrusive content creators help businesses to generate educative and entertaining content in form of articles and visuals.",
  },
  {
    image: Pr4,
    isButton: true,
    title: "Content Marketing",
    text: "Behind every successful brand, there's a successful utilization of innovative ideas to increase efficiency and profitability. Through our content marketing strategy, we help businesses gain and retain their market visibility and relevance.",
  },
  {
    image: Pr5,
    isButton: true,
    title: "Search Engine Optimization(SEO)",
    text: "Search engine algorithms are constantly revolutionizing, making it competitive for your brands on the search engine to rank higher organically. Our team of experienced marketers takes a holistic approach to paid search to ensure to give your brand a strong search engine ranking that serves specific marketing goals.",
  },
  {
    image: Pr6,
    isButton: true,
    title: "Search Engine Marketing(SEM)",
    text: "Behind every successful brand, there's a successful utilization of innovative ideas to increase efficiency and profitability. Through our content marketing strategy, we help businesses gain and retain their market visibility and relevance.",
  },
  {
    image: Pr7,
    isButton: true,
    title: "Paid Media",
    text: "We leverage digital evolution to drive brand awareness, audience, engagement and conversion with the aid of highly informed influencers.",
  },
  {
    image: Pr8,
    isButton: true,
    title: "Digital PR",
    text: "We deploy disruptive campaigns using a digitally-driven approach to shaping modern brands. We equip brands with the positioning tools and strategies they need to navigate online behaviour.",
  },
];
