import { useEffect } from "react";
import Service from "../../components/service";
import { trainingData } from "../../constants/services";
import classes from "../business/business.module.css";

const Training = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.main}>
      <div className={classes.bizzContent}>
        <span>OUR SERVICES</span>
        <h1>Training &amp; Human Capital Development</h1>
        <p>
          Our Human Capital Development programs are centered around upskilling
          and reskilling people for digital transformation and skill expansion.
          <br />
          We have customized and practical best-in-class training programs and
          services to meet your unique needs and position people in the search
          for their dream job.
        </p>
      </div>
      <div className={classes.bizzCard}>
        {trainingData.map((item, index) => (
          <Service
            index={index}
            {...item}
            key={index}
            modalImage={trainingData[index].image}
            modalText={trainingData[index].text}
            modalTitle={trainingData[index].title}
          />
        ))}
      </div>
    </div>
  );
};

export default Training;
