import React, { useEffect, useState } from "react";
import Service from "../../components/service";
import { businessData } from "../../constants/services";
import classes from "./business.module.css";

const Business = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.main}>
      <div className={classes.bizzContent}>
        <span>OUR SERVICES</span>
        <h1>Business & Management Consulting</h1>
        <p>
          We offer a wide variety of business and management consulting services
          that bridge the gap between the current and future state of your
          brand. Our management and business strategy consultancy proffers a
          suite of workable solutions and creates a clear roadmap to build your
          business growth plan and the development of your organization as a
          whole.
        </p>
      </div>
      <div className={classes.bizzCard}>
        {businessData.map((item, index) => (
          <Service
            index={index}
            {...item}
            key={index}
            modalImage={businessData[index].image}
            modalText={businessData[index].text}
            modalTitle={businessData[index].title}
          />
        ))}
      </div>
    </div>
  );
};

export default Business;
