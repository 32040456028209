import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./containers/home";
import Layout from "./hoc/layout";
import Contact from "./containers/contact";
import About from "./containers/about";
import Faq from "./containers/faq";
import Business from "./containers/business/business";
import Edu from "./containers/edu/edu";
import Training from "./containers/training/training";
import Travel from "./containers/travel/travel";
import Event from "./containers/event/event";
import Marketing from "./containers/marketing/marketing";

function App() {
  return (
    <div className="App">
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/faq" component={Faq} />
          <Route path="/service/business" component={Business} />
          <Route path="/service/edu-migration" component={Edu} />
          <Route path="/service/training" component={Training} />
          <Route path="/service/travel-tour" component={Travel} />
          <Route path="/service/event" component={Event} />
          <Route path="/service/marketing" component={Marketing} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
