import classes from "./modal.module.css";
import Mask from "../../assets/Mask.svg";

const Modal = ({ handleClose, modalTitle, modalText, modalImage }) => {
  return (
    <div className={classes.main}>
      <div className={`animate__animated animate__fadeInDown ${classes.modal}`}>
        <button onClick={handleClose}>
          <img src={Mask} alt="close" />
        </button>
        <div>
          <div className={`animate__animated animate__tada animate__infinite ${classes.modalImage}`}>
            <img src={modalImage} alt="" />
          </div>
          <h3>{modalTitle}</h3>
          <p>{modalText}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
