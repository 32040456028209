import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "./modal/modal";
import classes from "./_service.module.css";
import AOS from "aos";
const Service = ({
  image,
  title,
  text,
  text1,
  url,
  isButton,
  index,
  modalText,
  modalTitle,
  modalImage,
}) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {modal && (
        <Modal
          handleClose={() => setModal(false)}
          index={index}
          modalText={modalText}
          modalTitle={modalTitle}
          modalImage={modalImage}
        />
      )}
      <div className={classes.main} data-aos="zoom-in">
        <div className={classes.icons}>
          <img src={image} alt="" />
        </div>
        <h3>{title}</h3>
        {isButton ? <p>{text.substring(0, 100)}...</p> : <p>{text1}</p>}
        {isButton ? (
          <button onClick={() => setModal(true)}>Read More</button>
        ) : (
          <Link to={url}>Learn More</Link>
        )}
      </div>
    </>
  );
};

export default Service;
