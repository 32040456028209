import { useEffect } from "react";
import Service from "../../components/service";
import { marketData } from "../../constants/services";
import classes from "../business/business.module.css";

const Marketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.main}>
      <div className={classes.bizzContent}>
        <span>OUR SERVICES</span>
        <h1>Marketing, Communication & PR</h1>
        <p>
          Marketing isn't everyone's forte, but it is ours. We have every
          marketing weapon in our arsenal to set your brand on an upward
          trajectory through the infusion of all aspects of marketing
          communications together such as public relations, social media, sales
          promotion, and email marketing. <br />
          Our marketing communication and public relations services include:
        </p>
      </div>
      <div className={classes.bizzCard}>
        {marketData.map((item, index) => (
          <Service
            index={index}
            {...item}
            key={index}
            modalImage={marketData[index].image}
            modalText={marketData[index].text}
            modalTitle={marketData[index].title}
          />
        ))}
      </div>
    </div>
  );
};

export default Marketing;
