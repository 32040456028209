import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "animate.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);


