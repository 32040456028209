import { useEffect } from "react";
import Service from "../../components/service";
import { eduData } from "../../constants/services";
import classes from "../business/business.module.css";

const Edu = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.main}>
      <div className={classes.bizzContent}>
        <span>OUR SERVICES</span>
        <h1>Edu-Migration</h1>
        <p>
          Our EduMigration consultancy is aimed at providing a seamless process
          for educational ambition globally to fulfill career goals and a better
          lifestyle. <br /> We have several records of international admission
          and global immigration with a number of universities from the USA, UK,
          Canada, Australia, Turkey, New Zealand., Sweden, Germany, and Denmark,
          amongst others in the world.
        </p>
      </div>
      <div className={classes.bizzCard}>
        {eduData.map((item, index) => (
          <Service
            index={index}
            {...item}
            key={index}
            modalImage={eduData[index].image}
            modalText={eduData[index].text}
            modalTitle={eduData[index].title}
          />
        ))}
      </div>
    </div>
  );
};

export default Edu;
