import React from "react";
import classes from "./inputTwo.module.css";
const Input = ({
  _id,
  label,
  name,
  handleChange,
  value,
  errorMessage,
  isValid,
  hasSubmitted,
  placeholder,
}) => {
  const handleBlur = (isValid) => !isValid;

  return (
    <>
      <label htmlFor={_id} className={classes.label}>
        {label}
        <input
          id={_id}
          name={name}
          onChange={handleChange}
          value={value}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
        {!isValid && hasSubmitted && name !== "company" && (
          <span>{errorMessage}</span>
        )}
      </label>
    </>
  );
};

export default Input;
